import React from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  loadingPage: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

const Loading = _ => {

  const classes = useStyles();

  return (
    <div className={classes.loadingPage}>
      <CircularProgress/>
    </div>
  )
};

export default Loading;
