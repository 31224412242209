import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/firestore";

// TODO
//  get from env variables
//  local => .env
//  ci => ci variables in build step
const firebaseConfig = {
  apiKey: "AIzaSyDeacaNnnkWp7sXH_Oz5u0an9wRNFn8zVw",
  authDomain: "cfto-gestionale.firebaseapp.com",
  databaseURL: "https://cfto-gestionale.firebaseio.com",
  projectId: "cfto-gestionale",
  storageBucket: "cfto-gestionale.appspot.com",
  messagingSenderId: "194519131587",
  appId: "1:194519131587:web:e2001394b0dda630ae4976",
  measurementId: "G-3P21WMLBZG"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
