import {firestore} from "../firebase";

export const fetchCategories = () => {
  return new Promise(function (resolve, reject) {
    firestore.collection('categories').get()
      .then(snap => {
        return snap.docs.map(element => {
          return element.data()
        })
      })
      .then(categories => resolve(categories))
      .catch(e => reject(e))
  });
};

/**
 * New structure:
 * { CATEGORY => [SUBJECTS]}
 *
 * es:
 * {
 *  COURSE_FEE: ["MONTHLY_ONE", ... ],
 *  MEMBERSHIP_FEE: ["MEMBERSHIP", ... ],
 *  ...
 * }
 * @param rawCategories
 */
export const mapCategories = (rawCategories) => {
  return rawCategories.reduce((categories, category) => {
    let subjects = category.subjects.map(subject => subject.key);
    return {...categories, [category.key]: subjects};
  }, {})
}
