import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Language from '@crossfittorino/common/locale'

const resources = {
	en: {
		translation: Language.EN,
	},
	it: {
		translation: Language.IT,
	},
}

i18n.use(initReactI18next).init({
	resources,
	lng: 'it',
	fallbackLng: 'en',
	debug: false,
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
