import React, {useState} from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useFormValidation} from "../Form";
import {validateLogin} from "./validateLogin";
import {login} from "../../services/userService";
import CircularProgress from "@material-ui/core/CircularProgress";

const INITIAL_STATE = {
  email: "",
  password: ""
};


/**
 * Inspired by:
 * https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/sign-in/SignIn.js
 */
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  feedback: {
    color: theme.palette.error.dark,
    fontSize: 15,
    marginTop: 30
  }
}));


export const LoginForm = () => {
  const classes = useStyles();

  const [isSubmitting, setSubmitting] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState("");
  const {values, errors, isValid, handleChange, handleBlur} = useFormValidation(INITIAL_STATE, validateLogin);

  function handleSubmit(event) {
    event.preventDefault();
    setErrorFeedback("");

    if (isValid) {
      setSubmitting(true);
      const {email, password} = values;

      login(email, password)
        .then(() => {
          // Do nothing
        })
        .catch(err => {
          handleError(err);
          setSubmitting(false);
        })
    }
  }

  function handleError(error) {
    console.error(error);
    const {code} = error;
    let feedback;
    switch (code) {
      case "auth/user-not-found":
        feedback = "Utente non trovato";
        break;
      case "auth/wrong-password":
        feedback = "Password errata";
        break;
      default:
        feedback = `Errore sconosciuto [${code}]`;
    }
    setErrorFeedback(feedback);
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {isSubmitting ? <CircularProgress/> :
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
        }
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {errorFeedback && <div className={classes.feedback}> {errorFeedback} </div>}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            autoComplete="email"
            autoFocus
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={errors && errors.hasOwnProperty("email")}
            helperText={errors && errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors && errors.hasOwnProperty("password")}
            helperText={errors && errors.password}
          />
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox value="remember" color="primary"/>}*/}
          {/*  label="Ricordami"*/}
          {/*/>*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isValid || isSubmitting}
          >
            Invia
          </Button>
        </form>
      </div>
    </Container>
  );
};
