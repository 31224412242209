import {auth} from "../firebase";

export function checkIsLogged(cb) {
  auth.onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
      const userData = user.toJSON();
      // TODO handle this possible error
      const accessToken = userData.stsTokenManager.accessToken;
      saveUserToLocalStorage(accessToken);
      cb(true);
    } else {
      // No user is signed in.
      cb(false);
    }
  })
}

export function login(email, password) {
  return auth.signInWithEmailAndPassword(email, password);
}

export function logout() {
  return auth.signOut();
}

export function saveUserToLocalStorage(accessToken) {
  localStorage.setItem('accessToken', accessToken);
}

// TODO use for logout?
// export function deleteUserFromLocalStorage() {
//   localStorage.remove('accessToken');
// }

export function getUserAccessToken() {
  return localStorage.getItem('accessToken');
}
