import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Payments from "./pages/Payments";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Loading from "./pages/Loading";
import {checkIsLogged, logout} from "./services/userService";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';

/**
 * https://material-ui.com/customization/palette/#customization
 */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1171FF"
    }
  },
});


export default function App() {

  const [isLoading, setLoading] = useState(true);
  const [isLogged, setLogged] = useState(false);

  useEffect(() => {
    checkIsLogged((result) => {
      setLogged(result)
      setLoading(false);
    });
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? <Loading/> :
        isLogged ?
          <ContentHandler setLogged={setLogged}/> :
          <Login/>
      }
    </ThemeProvider>
  );
}


const ContentHandler = ({setLogged}) => {

  const handleLogout = async () => {
    await logout();
    setLogged(false);
  }

  const PaymentWrapped = () => (
    <Payments
      handleLogout={handleLogout}
    />
  );

  return (
    <>
      <CssBaseline/>
      <Router>
        <Switch>
          <Route exact path='/' component={PaymentWrapped}/>
          <Route exact path='/pagamenti' component={PaymentWrapped}/>
          <Route exact path='*' component={NotFound}/>
        </Switch>
      </Router>
    </>
  );
}
