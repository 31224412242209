import i18n from '../services/i18n';

// TODO
//  move in common
export const formatLocation = value => i18n.t(`DOMAIN.LOCATION.${value}`);
export const formatMode = value => i18n.t(`PAYMENT.MODE.${value}`);
export const formatSubject = value => i18n.t(`PAYMENT.SUBJECT.${value}`);
export const formatType = value => i18n.t(`PAYMENT.TYPE.${value}`);
export const formatCategory = value => i18n.t(`PAYMENT.CATEGORY.${value}`);


/**
 * @param seconds {int}
 */
export const formatDate = (seconds) => {
  // TODO check seconds param
  const date = new Date(seconds * 1000); // TODO handle possible error
  return date.toLocaleString('it-IT');
};
