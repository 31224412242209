import React from 'react'

export default function NotFound() {
  return (
    <>
      <h1>Page not found!</h1>
    </>
  )
};

