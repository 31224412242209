// TODO move to common
/**
 * Get the first day of the week of this date
 * @param date {Date}
 * @returns {Date}
 */
export const getFirstDayOfWeek = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
}

/**
 * @param date {Date}
 * @returns {Date}
 */
export const getFirstDayOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

/**
 * @param date {Date}
 * @returns {Date}
 */
export const getFirstDayOfYear = (date) => {
  return new Date(date.getFullYear(), 1, 1);
}
