import React from 'react'
import {LoginForm} from "../components/LoginForm";


/**
 * Login Page
 */
export default function Login() {

  return (
    <>
      <LoginForm/>
    </>
  );

};

