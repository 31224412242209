import {BASE_URL} from '../config'
import {getUserAccessToken} from "./userService";


// export const fetchPayments = async (period) => {
//
//   const accessToken = getUserAccessToken();
//
//   const response = await fetch(`${BASE_URL}/getPayments?period=${period}`, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`
//     }
//   });
//
//   if (!response.ok) {
//     let error = await response.json();
//     console.log(error);
//
//     if (response.status === 401) {
//       throw new Error("UNAUTHORIZED") // TODO use UnauthorizedError
//     }
//     throw new Error("Payment loading error");
//   }
//
//   return (await response.json()).data;
// };

export const exportPayments = async (year) => {

  const accessToken = getUserAccessToken();

  const response = await fetch(`${BASE_URL}/exportPayments?year=${year}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  const formatDateFile = (now = new Date()) => `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}_${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
  const fileName = `Pagamenti_${formatDateFile()}.xlsx`

  // 1. Convert the data into 'blob'
  const blob = await response.blob();
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
};
