import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    minWidth: 120
  }
}));

export default function PaymentesFilters({currentPeriod, PERIODS, handleSetPeriod, isLoading}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {PERIODS.map(period => (
        <Chip
          key={period.key}
          label={period.label}
          className={classes.chip}
          onClick={() => !isLoading && handleSetPeriod(period.key)}
          color={(currentPeriod === period.key) ? "primary" : "default"}
        />
      ))}
    </div>
  );
}
