import {useState, useEffect} from "react";


function useFormValidation(initialState, validate) {

  const [values, setValues] = useState(initialState);
  const [dirtyFields, setDirtyFields] = useState(new Set());
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);


  useEffect(() => {
    const isCurrentlyValid = Object.keys(errors).length === 0 && dirtyFields.size > 0;
    if (isCurrentlyValid !== isValid) setIsValid(isCurrentlyValid);
  }, [errors]);

  function handleChange(event) {
    let currentValue = event.target.name;

    if (!Object.keys(values).includes(currentValue)) {
      console.error("Form contain values not mapped!");
      return;
    }

    setDirtyFields((dirtyFields) => dirtyFields.add(currentValue));

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }

  function handleBlur() {
    const validationErrors = validate(values);
    // TODO set only errors of fields present in dirtyFields
    setErrors(validationErrors);
  }


  return {
    handleChange,
    handleBlur,
    values,
    errors,
    isValid
  };
}

export default useFormValidation;
