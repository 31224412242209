import React, {useEffect, useState} from 'react'
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import PaymentsTable from "../components/PaymentsTable/PaymentsTable";
import PaymentsFilters from "../components/PaymentsFilters/PaymentsFilters";
import {exportPayments} from "../services/paymentsService";
import {formatCategory, formatLocation, formatMode, formatType} from "../helpers/formatHelper";
import {fetchCategories, mapCategories} from "../services/categoriesService";
import firebaseService from "../services/firebasePaymentsService";
import firebase from '../firebase';

const {getPayments} = firebaseService(firebase);


const PERIODS = [
  {key: "DTD", label: "Oggi"},
  {key: "WTD", label: "Settimana corrente"},
  {key: "MTD", label: "Mese corrente"},
  {key: "YTD", label: "Anno corrente"},
  {key: "ALL", label: "Tutti"}
]

// TODO
//  define collection on firestore where are stored all definitions
const locationValues = ["VENARIA", "TORINO", "LEINI"];
const modeValues = ["CREDIT_CARD", "CASH", "ONLINE_PAYMENT"];
const typeValues = [true, false];

const getValidYears = () => {
  const firstYear = 2020;
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear; i >= firstYear; i--) {
    years.push(i);
  }
  return years;
}

/**
 * inspired by
 *  https://material-ui.com/getting-started/templates/
 *  https://material-ui.com/getting-started/templates/dashboard/
 */
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    // flexGrow: 1,
    marginRight: 10
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center'
  },
  paymentToolbar: {
    marginBottom: theme.spacing(7)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
  filters: {
    marginBottom: theme.spacing(4)
  },
  feedback: {
    color: theme.palette.error.dark,
    fontSize: 15,
    marginTop: 30,
    marginBottom: 30
  },
  logoutButton: {
    marginLeft: "auto"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonExport: {
    paddingTop: 16
  }
}));

const filterPayments = (payments, filters) => {
  const {selectedLocation, selectedMode, selectedType, selectedCategory} = filters;

  return payments.filter((payment) => {
    if (selectedLocation !== "" && payment.location !== selectedLocation) return false;
    if (selectedCategory !== "" && payment.category !== selectedCategory) return false;
    if (selectedMode !== "" && payment.mode !== selectedMode) return false;
    if (selectedType !== "" && payment.isExpense !== selectedType) return false;

    return true;
  })
}

export default function Payments({handleLogout}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Pagamenti");
  const [errorFeedback, setErrorFeedback] = useState("");
  // payments
  const [payments, setPayments] = useState(null);
  const [currentPayments, setCurrentPayments] = useState(null);
  const [totalCurrentPayments, setTotalCurrentPayments] = useState(0);
  // export payments
  const [period, setPeriod] = useState(PERIODS[0].key);
  const [exportYear, setExportYear] = useState((new Date().getFullYear()));
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  // filters
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [valuesCategory, setValuesCategory] = useState([]);


  const handleSelectedLocation = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleSelectedCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSelectedMode = (event) => {
    setSelectedMode(event.target.value);
  };

  const handleSelectedType = (event) => {
    setSelectedType(event.target.value);
  };

  const handleChangeYear = (event) => {
    setExportYear(event.target.value);
  };

  const handleSetPeriod = (period) => {
    setPeriod(period);
  }

  const handleExportYears = () => {
    setIsLoadingExport(true);
    exportPayments(exportYear)
      .then(_ => {
        setIsLoadingExport(false);
      })
      .catch(e => {
        console.error(e);
        setErrorFeedback("Errore sconosciuto nell'export dei pagamenti. Ricarica la pagina e contatta i developers.")
      });
  }

  useEffect(() => {
    fetchCategories()
      .then(categories => {
        const valuesCategory = Object.keys(mapCategories(categories));
        setValuesCategory(valuesCategory)
      })
      .catch(e => {
        console.log(e);
        setValuesCategory([])
      })
  }, [])

  useEffect(() => {
    setPayments(null);
    setCurrentPayments(null);

    getPayments(period)
      .then(data => setPayments(data))
      .catch(e => {
        setErrorFeedback("Errore sconosciuto nel caricamento dei pagamenti. Ricarica la pagina e contatta i developers.");
        console.error(e);
      });
  }, [period]);

  useEffect(() => {
    if (!Array.isArray(payments)) return;

    const filters = {selectedLocation, selectedMode, selectedType, selectedCategory};
    const filteredPayments = filterPayments(payments, filters);
    setCurrentPayments(filteredPayments);
  }, [payments, selectedLocation, selectedMode, selectedType, selectedCategory])

  useEffect(() => {
    if (!Array.isArray(currentPayments)) return;
    let total = currentPayments.reduce((total, elem) => total + parseFloat(elem.price), 0);
    setTotalCurrentPayments(total);
  }, [currentPayments])

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>
          {/*<EuroIcon/>*/}
          <IconButton color="inherit" className={classes.logoutButton} onClick={handleLogout}>
            <ExitToAppIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          {/* Toolbar */}
          <Paper className={classes.paymentToolbar}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <FormControl className={classes.formControl} disabled={!Array.isArray(currentPayments)}>
                  <InputLabel id="location-filter-label">Sede</InputLabel>
                  <Select
                    labelId="location-filter-label"
                    id="location-filter"
                    value={selectedLocation}
                    onChange={handleSelectedLocation}
                  >
                    <MenuItem value=""> <em>Tutti</em> </MenuItem>
                    {locationValues.map(location => (
                      <MenuItem
                        key={location}
                        value={location}
                      >{formatLocation(location)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl className={classes.formControl} disabled={!Array.isArray(currentPayments)}>
                  <InputLabel id="category-filter-label">Categoria</InputLabel>
                  <Select
                    labelId="category-filter-label"
                    id="category-filter"
                    value={selectedCategory}
                    onChange={handleSelectedCategory}
                  >
                    <MenuItem value=""> <em>Tutti</em> </MenuItem>
                    {valuesCategory.map(category => (
                      <MenuItem
                        key={category}
                        value={category}
                      >{formatCategory(category)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl className={classes.formControl} disabled={!Array.isArray(currentPayments)}>
                  <InputLabel id="mode-filter-label">Modalità</InputLabel>
                  <Select
                    labelId="mode-filter-label"
                    id="mode-filter"
                    value={selectedMode}
                    onChange={handleSelectedMode}
                  >
                    <MenuItem value=""> <em>Tutti</em> </MenuItem>
                    {modeValues.map(mode => (
                      <MenuItem
                        key={mode}
                        value={mode}
                      >{formatMode(mode)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl className={classes.formControl} disabled={!Array.isArray(currentPayments)}>
                  <InputLabel id="type-filter-label">Tipologia</InputLabel>
                  <Select
                    labelId="type-filter-label"
                    id="type-filter"
                    value={selectedType}
                    onChange={handleSelectedType}
                  >
                    <MenuItem value=""> <em>Tutti</em> </MenuItem>
                    {typeValues.map(type => (
                      <MenuItem
                        key={type}
                        value={type}
                      >{formatType(type)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="export-year">Anno</InputLabel>
                  <Select
                    labelId="export-year"
                    id="export-year-select"
                    value={exportYear}
                    onChange={handleChangeYear}
                    autoWidth
                  >
                    {getValidYears().map((year) => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl className={clsx(classes.formControl, classes.buttonExport)}>
                  <Button color="primary" variant="contained" onClick={handleExportYears} disabled={isLoadingExport}>
                    Esporta Pagamenti
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          {/* Period filters */}
          <div className={classes.filters}>
            <PaymentsFilters
              currentPeriod={period}
              PERIODS={PERIODS}
              handleSetPeriod={handleSetPeriod}
              isLoading={!Array.isArray(currentPayments)}
            />
          </div>
          {/* Feedback */}
          {errorFeedback && <div className={classes.feedback}> {errorFeedback} </div>}
          {/* Payments table */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align={"left"}>
                Totale: {totalCurrentPayments} &euro;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {(Array.isArray(currentPayments)) ?
                <PaymentsTable payments={currentPayments}/> :
                <CircularProgress disableShrink/>
              }
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
};

