import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {
  formatLocation,
  formatType,
  formatSubject,
  formatMode,
  formatDate,
  formatCategory
} from "../../helpers/formatHelper";


// example column format
// {
//   id: 'density',
//   label: 'Density',
//   minWidth: 170,
//   align: 'right',
//   format: (value) => value.toFixed(2),
// },
const columns = [
  {id: 'title', label: 'Nominativo'},
  {id: 'price', label: 'Costo', format: (value) => `${value} €`},
  {id: 'subject', label: 'Oggetto', format: formatSubject},
  {id: 'category', label: 'Categoria', format: formatCategory},
  {id: 'date', label: 'Data', format: (value) => formatDate(value.seconds)},
  {id: 'location', label: 'Sede', format: formatLocation},
  {id: 'mode', label: 'Modalità', format: formatMode},
  {id: 'isExpense', label: 'Tipologia', format: formatType},
  {id: 'info', label: 'Dettagli'},
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 400px
  },
});

export default function PaymentsTable({payments}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const tableData = Array.isArray(payments) ? payments : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Righe per pagina"}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
