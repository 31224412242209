import {fetchCategories} from "./categoriesService";
import {getFirstDayOfMonth, getFirstDayOfWeek, getFirstDayOfYear} from "../utils";

/**
 * TODO move to common
 * @param firebase
 */
export default function firebaseService(firebase) {

  const firestore = firebase.firestore();


  // TODO use redux?
  const getPayments = (period = "DTD") => new Promise((resolve, reject) => {

    const promisePayments = fetchPayments(period);
    const promiseCategories = fetchCategories();

    Promise.all([promisePayments, promiseCategories])
      .then(([payments, categories]) => {
        const subjectCategoryMap = mapSubjectToCategory(categories);
        const paymentsWithCategory = addCategoryToPayments(payments, subjectCategoryMap)
        resolve(paymentsWithCategory)
      })
      .catch(e => reject(e))
  });


  const fetchPayments = (period) => {

    // Period logic
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setMilliseconds(0);
    today.setSeconds(0);

    let targetDate = today;
    if (period === "DTD") {
      targetDate = today;
    }
    if (period === "WTD") {
      targetDate = getFirstDayOfWeek(today);
    }
    if (period === "MTD") {
      targetDate = getFirstDayOfMonth(today);
    }
    if (period === "YTD") {
      targetDate = getFirstDayOfYear(today);
    }
    if (period === "ALL") {
      targetDate = new Date(0);
    }

    const paymentsRef = firestore.collection("payments");

    return new Promise(((resolve, reject) => {
      paymentsRef
        .where("date", ">=", targetDate)
        .orderBy("date", "desc")
        .get()
        .then(snapshot => {
          const payments = snapshot.docs.reduce((filtered, payment) => {
            if (!payment.data().hasOwnProperty("deletedAt")) {
              filtered.push({id: payment.id, ...payment.data()});
            }
            return filtered;
          }, []);

          resolve(payments)
        })
        .catch(e => reject(e))
    }));
  }

  // const fetchCategories = () => {
  //   return new Promise(function (resolve, reject) {
  //     firestore.collection('categories').get()
  //       .then(snap => {
  //         return snap.docs.map(element => {
  //           return element.data()
  //         })
  //       })
  //       .then(categories => resolve(categories))
  //       .catch(e => reject(e))
  //   });
  // };

  /**
   * Goal structure:
   * {
   *  SUBJECT: CATEGORY,
   *  SUBJECT: CATEGORY,
   *  ...
   * }
   * @param categories
   */
  const mapSubjectToCategory = (categories) => {
    let subjects = {};

    for (let c of categories) {
      let s = c.subjects.reduce((acc, elem) => {
        return {...acc, [elem.key]: c.key}
      }, {})
      subjects = {...subjects, ...s};
    }
    return subjects;
  }

  /**
   * Add categories to payments object
   */
  const addCategoryToPayments = (payments, subjectToCategory) =>
    payments.map(payment => {
      let category = subjectToCategory[payment.subject] || "UNKNOWN";
      if (!category) {
        console.error("Subject not in category:" + payment.subject);
      }
      return {...payment, category}
    });


  return {
    getPayments
  }
}

